@media (max-width: $screen-sm) {

  .header-loyalty-container {
    width: auto !important;
    padding-left: 10px !important;
  }

  #site-header .col-sm-4 {
    padding-bottom: 5px;
  }

  .page-body .aside-contents-container {
    top: 44px !important;
  }
}

@media (min-width: $screen-sm) {
  .navbar-nav > li > a {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}

@media (max-width: $screen-xs) {
  .show-for-xs {
    display: block !important;
  }

  .hide-for-xs {
    display: none !important;
  }

  #site-header .loyalty-profile,
  .header-loyalty-container {
    padding-right: 0;
    margin-right: 0;
  }

  #site-header  {
    .loyalty-profile,
    .points {
      display: block !important;
    }
  }
}