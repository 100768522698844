// Global styles for ALL cases

body {
  position: relative;
}

body.iframed-contents {
  background-color: transparent;
}

// Links

a {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: $link-color;
  text-decoration: $link-text-decoration;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    @include tab-focus($brand-primary);
  }
}

a:hover,
[data-toggle="collapse"] {
  cursor: pointer;
}

input::placeholder {
  font-style: $input-placeholder-style;
}

//manages focus states on handheld and desktop
//handheld w/ bluetooth keyboard use cases
:focus-visible {
  outline: 2px solid $brand-primary;
}

.btn-text-input,
select {
  &:focus-visible {
    outline-offset: -2px;
  }
}

a {
  :focus-visible {
    outline: 2px solid $brand-primary;
  }
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
  }
}

.pagination {
  > .active > a {
    outline: 2px solid currentColor;
    &:focus {
      outline-offset: -3px !important;
      outline-color: currentColor !important;
    }
  }
}

//Used with heading styles below
%margin {
  &.align-top {
    margin-top: 0;
  }
}

h1,
.h1 {
  margin-top: $h1-margin-vertical;
  margin-bottom: $h1-margin-vertical;
  font-weight: $headings-font-weight-h1;
  font-size: $font-size-h1;
  color: $headings-text-color-h1;
  @extend %margin;

  small.block {
    line-height: inherit;
    margin-top: $padding-base-vertical*2;
  }
}

h2,
.h2 {
  margin-top: $h2-margin-vertical;
  margin-bottom: $h2-margin-vertical;
  font-weight: $headings-font-weight-h2;
  font-size: $font-size-h2;
  color: $headings-text-color-h2;
  @extend %margin;
}

h3,
.h3 {
  margin-top: $h3-margin-vertical;
  margin-bottom: $h3-margin-vertical;
  font-weight: $headings-font-weight-h3;
  font-size: $font-size-h3;
  color: $headings-text-color;
  @extend %margin;
}

h4,
.h4 {
  margin-top: $h4-margin-vertical;
  margin-bottom: $h4-margin-vertical;
  font-weight: $headings-font-weight-h4;
  font-size: $font-size-h4;
  color: $headings-text-color;
  @extend %margin;
}

.d-inline-block {
  display: inline-block;
  // There's an existing .inline-block class that isn't to be confused with this
}

.text-attn {
  display: none;
}

.input-group,
.form-control,
.error .text-attn, .btn-text-input {
  margin-bottom: calc(#{$input-height-base} / 2);
  border-radius: $input-border-radius;
}

// Non input form items spacing helper must match margin-bottom from .form-control
.form-margin {
  margin-bottom: $input-height-base / 2;
}

.input-daterange .input-group input {
  &:first-child {
    border-radius: $input-border-radius;
  }

  &:last-child {
    border-radius: $input-border-radius;
  }
}

.input-group .twitter-typeahead input {
  border-radius: 0 $input-border-radius $input-border-radius 0;
}

legend {
  border: 0;
  overflow: hidden;
}

legend.legend-alt {
  background: $legend-alt-bg-color;
  color: $legend-alt-text-color;
  padding: $padding-large-vertical $padding-small-horizontal;
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  line-height: normal;
  @include box-shadow($input-box-shadow);
  @extend .form-control;
}

textarea:not([data-resize=true]) {
  resize: none;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9
; // IE8-9
  line-height: normal;
}

// These have to be selected individually
// a11y - Do not change opacity
::-webkit-input-placeholder {
  font-style: $input-placeholder-style;
  opacity: 0.9;
}

:-moz-placeholder {
  font-style: $input-placeholder-style;
  opacity: 0.9;
}

::-moz-placeholder {
  font-style: $input-placeholder-style;
  opacity: 0.9;
}

:-ms-input-placeholder {
  font-style: $input-placeholder-style;
  opacity: 0.9;
}

.list-group {
  button {
    @extend .text-left;
    padding: $padding-large-vertical $padding-large-horizontal;
    border: none;
    border-bottom: $list-group-border;
    background-color: $list-group-bg-color;

    &:hover, &:focus, &:active {
      background-color: $list-group-active-bg;
      color: $list-group-active-color;
      border-color: $list-group-active-border;
    }
  }

  &.no-margin {
    > li {
      border-left: 0;
      border-right: 0;
    }
  }

  li:last-of-type button {
    border-bottom: none;
  }
}

/*TODO scott check the wrapping*/
// Buttons
.btn.text-left {
  text-align: left !important;
}

.btn-icon-uncommon {
  .icon-container {
    width: auto;
  }
}

.btn-icon-right {
  .icon-container {
    margin-left: $padding-small-vertical;
  }
}

.btn-icon-left {
  .icon-container {
    margin-left: $padding-small-vertical;
  }
}

a:focus,
.btn-link:focus {
  outline-offset: 1px !important;
}

[tabindex="-1"]:focus {
  outline: none;
}

.remove-btn {
  color: $remove-btn-color;
  margin-left: $padding-small-horizontal;
  &:hover {
    color: $remove-btn-hover-color;
  }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    display: inline-block;
    min-height: $line-height-computed; // Ensure the input doesn't jump when there is no text
    padding-left: 20px;
    padding-right: $padding-large-horizontal;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;

    label {
      // for weird cases where label is inside label (incorrectly)
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  margin-top: 4px \9
;
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; // space out consecutive inline controls
}

.select-info {
  // Style a select dropdown as info type
  background-color: $select-info-bg-color;
  color: $select-info-text-color;
  border-color: $select-info-border-color;
}

.product-loading .select-info {
  background-color: $input-bg;
  color: $input-color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

// Remove input clear text X button that IE10/11 browsers add for .typeahead elements - Also, double colon here is OK given -ms-clear doesn't exit in IE8 anyway.
input[type="text"].typeahead::-ms-clear {
  display: none;
}

.inline-radio {
  margin: 0 $padding-base-horizontal 0 0;

  > input[type=radio] {
    margin: 0 2px 0 0;
  }
}

.form-group:last-of-type .inline-block select,
.form-group:last-of-type {
  margin-bottom: 0;
}

// Sets legends to the same font-size as h3 - this should be consistent given that section headers will start at h2
legend {
  @include component-h3;
}

// Fixes legends not obeying bootrap columns on tablets
legend.col-fix {
  display: block;
  float: left;
}

label {
  margin-bottom: 0;
  cursor: default;
  font-weight: $form-label-font-weight;
  width: 100%;
  //override inline-block in _forms.scss to fix the space between inline-block elements
  display: block;

  input,
  select,
  textarea,
  .input-group {
    margin-top: 3px;
  }

  .input-group {
    width: 100%;
  }
}

.input-group input {
  margin-top: 0;

  &.datepicker + .input-group-btn {
    border-top-right-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
  }
}

img {
  word-break: break-all;
}

.img-center {
  margin: 0 auto;
}

.img-full {
  width: 100%;
}

.img-padded {
  padding: 0 10px;
}

// Navbar
//-----------------------------
.navbar-inverse label {
  color: #fff;
}

.navbar-no-toggle {
  min-height: initial;
  position: relative;

  .navbar-right {
    margin-right: 20px;
  }

  .toggle-button {
    position: absolute;
    top: 14px;
    right: 10px;
  }
}

// Navbar nav links
//
// Builds on top of the `.nav` components with it's own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

%dropdown-menu {
  @media (max-width: $screen-xs-max) {
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }
      > li > a {
        line-height: $line-height-computed;
        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }
  }
}

.navbar-nav {
  margin: ($navbar-padding-vertical / 2) -$navbar-padding-horizontal;

  > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: $line-height-computed;
  }

  @extend %dropdown-menu;

  // Uncollapse the nav
  @media (min-width: $grid-float-breakpoint) {
    float: left;
    margin: 0;

    > li {
      float: left;
      > a {
        padding-top: (($navbar-height - $line-height-computed) / 2);
        padding-bottom: (($navbar-height - $line-height-computed) / 2);
      }
    }
  }
}

#client-header-navbar {
  .btn-link {
    color: $navbar-default-link-color;
  }

  a {
    text-decoration: $site-header-link-text-decoration;

    &:hover {
      text-decoration: $site-header-link-hover-text-decoration;
    }
  }

  .header-loyalty-nav {
    @extend %dropdown-menu;
    .dropdown-menu {
      a:hover {
        text-decoration: $site-header-loyalty-link-hover-text-decoration;
      }
    }
  }
}

// Dropdown Menu styles
//------------------------------

.dropdown.open {
  .icon-nav__dropdown {
    transform: rotate(180deg) translateY(-20%);
  }
}
.dropdown-menu.menu-right {
  right: 0;
  left: auto;
  margin-top: $padding-small-vertical;
}

// Adds a mask (like a modal backdrop) for any nav .dropdown that also has the .dropdown-masked class
.dropdown-masked.open > .dropdown-menu {
  box-shadow: 0 100vh 0 100vh rgba(0, 0, 0, .5),
  0 5px 5px rgba(0, 0, 0, .5);
}

.addition-fee-description {
  font-size: $font-size-base;
}

.loyalty-discount {
  div.dropdown-menu {
    margin-top: $loyalty-discount-dropdown-menu-margin-top;

    ul > li > a {
      color: $loyalty-discount-dropdown-menu-link-color;
      white-space: nowrap; // prevent links from randomly breaking onto new lines
      &:hover,
      &:hover div {
        color: $loyalty-discount-dropdown-menu-hover-color;
        text-decoration: underline;
      }
    }

    &.pull-right,
    &.pull-left {
      &:after {
        content: $loyalty-discount-dropdown-menu-has-caret;
        position: absolute;
        bottom: 100%;
        margin-left: -5px;
        border-width: 9px;
        border-style: solid;
        border-color: transparent transparent #fff;
      }
    }

    &.pull-right:after {
      right: 10px;
    }

    &.pull-left:after {
      left: 10px;
    }
  }
}

.modal-content {
  box-shadow: $modal-content-box-shadow;
}

hr, .hr {
  margin-top: $line-height-computed !important;
  margin-bottom: $line-height-computed; //!important
  border: 0;
  border-top: 1px solid $hr-border;
}

hr.hr-lg,
.hr.hr-lg,
.hr-text.hr-lg {
  margin: $padding-large-vertical*3 0 !important;
}

hr.hr-sm,
.hr.hr-sm {
  margin: $line-height-computed-sm 0 !important;
}

hr.hr-xs,
.hr.hr-xs {
  margin: $line-height-computed/3 0 !important;
}

.hr-text {
  @include hrText($body-bg, currentColor);
}

.aside-contents-container .hr-text {
  @include hrText($aside-contents-section-background-color, currentColor);
}

.aside-contents-container {
  .close.visible-tablet-inline-block {
    opacity: initial;
    color: $aside-contents-container-close-btn-color;
  }
}

.badge {
  font-weight: normal;
}

.text-xs {
  font-size: $font-size-xs;
}

.text-small {
  font-size: $font-size-small;
}

.text-large {
  font-size: $font-size-large;
  line-height: $line-height-large;
}

.text-normal {
  font-weight: $text-normal-font-weight;
}

.text-medium {
  font-weight: $text-medium-font-weight;
}

.text-strong,
strong,
b {
  font-weight: $text-strong-font-weight;
}

.text-italic {
  font-style: italic;
}

.text-valign-with-btn {
  padding-top: $padding-base-vertical;
  padding-bottom: $padding-base-vertical;
  line-height: $line-height-base;
}

.text-valign-with-input {
  padding-top: (($input-height-base / 2) - ($font-size-base / 2) -2);
  padding-bottom: (($input-height-base / 2) - ($font-size-base / 2) -2);
}

.p {
  margin-bottom: calc(#{$line-height-computed} / 2) !important;
  // Use for a common bottom margin that mimics the bottom padding of <p>
  // e.g. When you need a Block element (like <div>) but a paragraph's bottom offset with nearby content.
}

.p-sm {
  margin-bottom: ($padding-base-vertical) !important;
  // Similar to above .p style, but not as heavy of bottom margin
}

.p-lg {
  margin-bottom: $line-height-computed;
}

.p-lg-top {
  margin-top: $line-height-computed;
}

.p-md-top {
  margin-top: $line-height-computed/2;
}

//prevent single comma on next line
.auto-commas {
  span:nth-of-type(n + 1):not(:last-child) {
    &::after {
      content: ", ";
    }
  }
}

// Adding helper class to reset default margin values assigned to h classes e.g h2/.h2 h3/.h3
.h {
  margin-top: 0;
  margin-bottom: ($padding-large-vertical * 2);
}

.toolbar {
  @extend .clearfix;
  @extend .well;
  @extend .well-sm;

  input,
  select {
    margin: 0;
  }

  .select-dropdown:after {
    bottom: 0;
  }

  .btn-group-pills > .btn {
    margin-bottom: $padding-large-vertical;
  }
}

// Using .brand inherits things from bootstrap we don't want
.logo-primary,
.logo-secondary {
  display: inline-block;
}

.text-muted {
  color: $text-muted;
}

.text-disabled {
  color: $text-disabled;
}

.error {
  @include form-control-validation($state-danger-text, $state-danger-border, $state-danger-bg);
}

#insurance-forced-choice.validation-error {
  border: 1px solid $state-danger-border;
  margin-bottom: $input-height-base / 2;
}

.warning > label {
  color: $brand-warning;
}

.warning {
  @extend .has-warning;
}

.text-warning {
  color: $brand-warning;
}

.white-space-pre {
  white-space: pre;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}


.test-bar {
  cursor: pointer;

  &:before {
    font-family: "switchflyShopping";
    content: $icon-collapsible--closed;
    position: absolute;
    top: 6px;
    left: 8px;
  }

  &.dev-bars-open:before {
    content: $icon-collapsible--opened;
  }
}

.tiny-logo {
  height: 28px !important;
  width: 28px !important;
}

.tiny-logo-sm {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.tiny-logo-xs {
  height: 16px !important;
  width: 16px !important;
}

// Brand backgrounds
// Applies theme colors (from app-theme.scss) as background-color
// and dynamically sets color to either #333 or #fff based on lightness of background

.background-gray-dark {
  background: $gray-dark;
  color: dynamicColor($gray-dark);
}

.background-gray {
  background: $gray;
  color: dynamicColor($gray);
}

.background-gray-medium {
  background: $gray-medium;
  color: dynamicColor($gray-medium);
}

.background-gray-ccc {
  background: #ccc;
  color: dynamicColor(#ccc);
}

.background-gray-light {
  background: $gray-light;
  color: dynamicColor($gray-light);
}

.background-gray-lighter {
  background: $gray-lighter;
  color: dynamicColor($gray-lighter);
}

.background-almost-white {
  background: $almost-white;
  color: dynamicColor($almost-white);
}

.background-white {
  background: #fff;
  color: dynamicColor(#fff);
}

.background-primary {
  background: $brand-primary;
  color: dynamicColor($brand-primary);
}

.background-success {
  background: $brand-success;
  color: $brand-success-text-color;
}

.background-warning {
  background: $brand-warning;
  color: $brand-success-text-color;
}

.background-danger {
  background: $brand-danger;
  color: #fff;
}

.background-info {
  background: $brand-info;
  color: $brand-info-text-color;
}

.background-active {
  background: $brand-active;
  color: $brand-active-text-color;
}

.background-special {
  background: $brand-special;
  color: $brand-special-text-color;
}

.background-featured {
  background: $brand-featured;
  color: $brand-featured-text-color;
}

.background-alternate {
  @extend .background-gray-ccc;
}

.text-attn,
.text-required {
  color: $brand-danger;
}

.error .error-text {
  display: block;
  padding: $padding-base-vertical 0 $padding-base-horizontal;
  background: $input-validation-error-background-color;
  color: $input-validation-error-text-color;
  position: relative;
  font-weight: $form-validation-text-font-weight;
  margin-top: -($input-height-base / 2);
}

.error {

  .input-group {

    input, select {
      border-radius: 0 0 0 $input-border-radius;
    }

    > span {
      border-radius: 0 0 $input-border-radius 0;
      border-color: $state-danger-border;
      position: relative;
      left: -1px;
    }
  }
}

#placeholder-for-validation.error input {
  border-radius: $input-border-radius;
  // Required for discount-code input b/c we don't actually inline the validation error message (yet)
}


.text-special {
  color: $brand-special;
}

.panel-body > h3 {
  margin-top: 0;
}

.panel-notice {
  > div {
    padding-left: $padding-large-horizontal * 2;
  }
}

.alert {
  @include alert-variant($alert-default-bg, $alert-default-border, $alert-default-text);
  > * + * {
    margin-left: $padding-small-horizontal / 2;
  }
}

.alert-success {
  @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
   i {
    color: $alert-success-icon-color;
  }
}

.alert-info {
  @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
   i {
    color: $alert-info-icon-color;
  }
}

.alert-warning {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
   i {
    color: $alert-warning-icon-color;
  }
}

.alert-danger {
  @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
   i {
    color: $alert-danger-icon-color;
  }
}

.alert span[class] {
  min-height: 0;
}

.alert-error button {
  display: none;
}

.alert > div {
  margin-top: $alert-margin-top;
}

.alert .flash-message div {
  border-bottom: 1px dotted; // Defaults to alert text color
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.alert .flash-message div:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.alert .close {
  opacity: 0.8;

  &:hover, &:active {
    opacity: 1;
  }
}

.alert {
  a,
  .alert-link {
    text-decoration: $alert-link-decoration;
    &:hover,
    &:focus,
    &:active {
      text-decoration: $alert-link-decoration-hover;
    }
  }
}

.tooltip {
  @include transition(opacity 100ms linear);

  &.in {
    @include transition-delay(300ms);
  }

  .tooltip-inner {
    word-wrap: break-word;
    font-size: $tooltip-font-size;
    line-height: $tooltip-line-height;
    text-align: left;
    border-radius: $tooltip-border-radius;
  }
}

.c-tooltip {
  display: inline-block;
  position: relative;

  .tooltip {
      width: max-content;

      &.top {
          transform: translateX(-50%) translateY(-100%);
          top: 0;
          left: 50%;
      }

      &.right {
          transform: translateY(-50%);
          top: 50%;
          left: 100%;
      }

      &.bottom {
          transform: translateX(-50%);
          top: 100%;
          left: 50%;
      }

      &.left {
          transform: translateY(-50%) translateX(-100%);
          top: 50%;
          left: 0;
      }
  }
}

.points-allocation-slider .tooltip {
  min-width: 250px;
}

//
// Popovers
// --------------------------------------------------

.popover-container {
  position: relative;

  &.flex-container > div {
    margin-left: auto;
  }
}

.popover {
  min-width: $popover-min-width;
  max-width: $popover-max-width;
  font-size: $font-size-base;
  background-color: $popover-bg;
  border: 1px solid $popover-border-color;
  border-radius: $popover-border-radius;
  box-shadow: $popover-box-shadow;

  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: none;
  padding: 1px;
  text-align: left;
  @include box-shadow(0 5px 10px rgba(0, 0, 0, .2));

  // Overrides for proper insertion
  white-space: normal;

  // Offset the popover to account for the popover arrow
  &.top {
    margin-top: -10px;
  }
  &.right {
    margin-left: 10px;
  }
  &.bottom {
    margin-top: 10px;
    .arrow {
      border-bottom-color: $popover-arrow-outer-color;
    }
  }
  &.left {
    margin-left: -10px;
  }

  &.popover-md {
    min-width: $popover-md-min-width;
    max-width: $popover-md-max-width;
  }

  .popover-body {
    padding: 0;
    margin: $popover-body-spacing-v $popover-body-spacing-h;

    &.popover-body-alt {
      margin: 0;
      background: $popover-body-bg-alt;
      padding: $popover-body-spacing-v $popover-body-spacing-h;
    }

    &.top .arrow {
      left: 50%;
      margin-left: -$popover-arrow-outer-width;
      border-bottom-width: 0;
      border-top-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        bottom: 1px;
        margin-left: -$popover-arrow-width;
        border-bottom-width: 0;
        border-top-color: $popover-arrow-color;
      }
    }

    &.right .arrow {
      top: 50%;
      left: -$popover-arrow-outer-width;
      margin-top: -$popover-arrow-outer-width;
      border-left-width: 0;
      border-right-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        left: 1px;
        bottom: -$popover-arrow-width;
        border-left-width: 0;
        border-right-color: $popover-arrow-color;
      }
    }

    &.bottom .arrow {
      left: 50%;
      margin-left: -$popover-arrow-outer-width;
      border-top-width: 0;
      border-bottom-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        top: 1px;
        margin-left: -$popover-arrow-width;
        border-top-width: 0;
        border-bottom-color: $popover-arrow-color;
      }
    }

    &.left .arrow {
      top: 50%;
      right: -$popover-arrow-outer-width;
      margin-top: -$popover-arrow-outer-width;
      border-right-width: 0;
      border-left-color: $popover-arrow-outer-color;

      &:after {
        content: " ";
        right: 1px;
        border-right-width: 0;
        border-left-color: $popover-arrow-color;
        bottom: -$popover-arrow-width;
      }
    }

    fieldset {
      padding: $padding-large-vertical $padding-large-horizontal;
    }
  }

  .popover-footer {
    border-top: $popover-footer-border-top;
    padding: $popover-body-spacing-v;

    button + button {
      margin-left: 10px;
    }
  }

  &.recent-searches-popover {
    left: inherit !important;
    right: 0;
    padding: 0;
    max-width: 30vw;
    border-radius: $popover-border-radius;

    .arrow {
      left: 75% !important;
    }

    strong {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.popover-title {
  font-size: $font-size-h4;
  font-weight: $popover-title-font-weight;
  line-height: $font-size-base + 10px;
  background-color: $popover-title-bg;
  color: $popover-title-text-color;
  border-bottom: $popover-title-border;
  border-radius: $popover-border-radius;
  margin: 0;
  // reset heading margin
  padding: 8px 14px;
}

.iframed-contents {
  overflow-x: hidden;
  padding-right: $modal-inner-padding;
  word-break: break-word;
}

iframe {
  margin-left: 0;
}

iframe[seamless] {
  // Adds styling for browsers that don't yet support seamless, except lte IE8 sadly
  background-color: transparent;
  border: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

.iframe-seamless-container {
  outline: $iframed-content-border-width solid $iframed-content-border-color;
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-right: 0;
}

#iframe-discovery {
  width: 1px;
  min-width: 100%;
}

.policies-iframe-seamless-container {
  &:focus {
    box-shadow: 0 0 5px 2px $link-color;
  }
}

.show-for-xs {
  display: none;
}

// Targets handheld (480px width) and below
// --------------------------------------------------
@media (max-width: $screen-xs) {
  .iframe-seamless-container {
    max-height: 30vh;
  }

  .hide-for-xs {
    display: none;
  }

  .show-for-xs {
    display: block;
  }
}

.iframe-seamless-contents {
  padding-left: 10px;
}

.previousBalance, .previousBalance .price {
  color: $brand-primary;
  font-weight: $text-strong-font-weight;
}

.removable {

  td,
  i,
  th {
    color: $removable-text-color;
  }

  td .price {
    color: $discount-price-text-color;
  }

  td .points {
    color: $discount-points-text-color;
  }

  td .plus {
    color: $removable-text-color;
  }

  a {
    color: $removable-link-color;

    &:hover, &:active, &:focus {
      color: $removable-link-hover-color;

      > .icon {
        color: $removable-link-hover-color;
      }
    }
  }

}

a.removable {
  color: $removable-link-color;

  &:hover, &:active, &:focus {
    color: $removable-link-hover-color;

    > .icon {
      color: $removable-link-hover-color;
    }
  }
}

th.removable {
  color: $removable-text-color;
}

li > .icon-product__selected { // When icon-ok is used inline in list elements
  color: $list-product-selected-color;
}

.header-icon-product__selected {
  color: $product-check-color;

  &.completed {
    color: $product-check-complete-color;
  }
}

.page-body {
  background: $page-body-background-color;
  @extend .clearfix;
  margin-bottom: $page-body-margin-bottom;

  .page-header {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}

.page-header {
  background: $page-header-background-color;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;

  section {
    @extend .p;

    &:last-of-type {
      padding-bottom: 10px;
      border-bottom: 1px solid $base-border-color;
    }
  }

  &.page-header-hero {
    background: $page-header-hero-background-color;
    margin-bottom: 0;

    @media (max-width: $screen-sm) {
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }

    h1 {
      padding: $padding-large-horizontal;
      color: $page-header-hero-text-color;

      .text-small {
        font-weight: normal;
      }

      > div {
        padding: $section-common-padding;
      }

      @media (max-width: $screen-sm) {
        .text-center {
          text-align: left;
          padding-top: $padding-large-vertical;
        }
      }

      @media (min-width: $screen-sm) {
        &.row {
          @include flexbox;
          align-items: center;
        }
      }
    }
  }
}

.separator {
  @extend .text-muted;
  cursor: default;
  padding: 0 10px;
}

.nav-list li:hover {
  cursor: pointer;
}

.nav-list .active:hover {
  cursor: default;
}

.nav > li > a,
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: transparent;
  border: none;
}

// Scrollable tabs

.nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;

  >li {
    > a {
      border-radius: $nav-tabs-border-radius;
    }
  }

  & > li {
    float: none;
    display: inline-block;
  }
}

.nav-tabs::-webkit-scrollbar {
  width: 0 !important;
}

.nav-tabs {
  box-shadow: inset 0 -1px 0 0 $nav-tabs-border-color;
  border: none;

  &#horizontal-sort-options {
    box-shadow: none;

    .trip-advisor-icon {
      padding: 2px;
      height: 20px;
    }
  }

  > li {
    > a {
      color: $nav-tabs-link-color;
      padding: $nav-link-padding;
      text-decoration: $nav-tabs-link-text-decoration;

      &:hover,
      &:focus {
        background-color: $nav-tabs-bg-color-hover;
        box-shadow: $nav-tabs-active-link-hover-border;
        color: $nav-tabs-active-link-hover-color;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    &.active > a {
      &,
      &:hover,
      &:focus {
        border: none;
        box-shadow: inset 0 -3px 0 0 $nav-tabs-active-link-hover-border-color;
        font-weight: $text-strong-font-weight;
      }
    }
  }
}

.nav-pills {
  @include nav-pills-variant(
      $nav-pills-padding-bottom,
      $nav-pills-border-radius,
      $nav-pills-border-style,
      1,
      $nav-pills-active-link-hover-bg,
      $nav-pills-hover-bg,
      $nav-pills-hover-font-color,
      $nav-pills-active-link-hover-color,
      $nav-pills-active-font-color,
      $nav-pills-link-active-font-weight,
      $nav-pills-link-color,
      $nav-pills-link-font-weight);
}

.nav-pills-alt {
  @include nav-pills-variant (
      $nav-pills-alt-padding-bottom,
      $nav-pills-alt-border-radius,
      $nav-pills-alt-border-style,
      initial,
      $nav-pills-alt-active-bg-color,
      $nav-pills-alt-hover-bg-color,
      $nav-pills-alt-hover-font-color,
      transparent,
      $nav-pills-alt-active-font-color,
      $nav-pills-alt-active-font-weight,
      $nav-pills-alt-font-color,
      $nav-pills-alt-font-weight);
}

.visible-tablet-block,
.visible-tablet-inline-block {
  // This is set to display: inline-block in app-responsive
  display: none;
}

// Case helper classes; visible-base shows only for case-base, visible-handheld only for case-handheld
.visible-base,
.visible-handheld {
  display: none;
}

.case-base .visible-base,
.case-handheld .visible-handheld {
  display: block;
}

.case-base #shopping-page-container.overflow .page-body {
  overflow: inherit;
  margin-bottom: 0;
}

section > header,
.section-body,
section footer,
.component > header {
  @include clearfix;
}

//resetting the above code when flexbox is used in <header>
section > header.flex-container.space-between {
  &:before,
  &:after {
    display: none !important;
  }
}

section h2 {
  margin: 0;
}

.section-body {
  padding: 10px 0;
}

.section-footer {
  border-top: 1px solid $gray;
}

.component-section {
  @extend .p;
  padding-bottom: 10px;
  border-bottom: 1px solid $gray-lighter;

  &.air-leg {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  + .component-section {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  &:last-of-type:not(.deposit-module-section) {
    border-bottom: none;
  }

  h3,
  .h3:not(.modal-title) {
    @include component-h3;
  }
}

// Financial Sections

.price {
  color: $price-text-color;
  font-family: $price-font-family;
}

.points,
input.points {
  color: $points-text-color;
}

.price-discount,
.price-discount .price {
  color: $discount-price-text-color;
}

.price-strikethrough,
.price-strikethrough * { // asterisk to also target inline-blocked child elements
  text-decoration: line-through;
  text-decoration-color: $strikethrough-text-decoration-color;
  color: $strikethrough-text-color;
  font-size: $font-size-base; //reset when it's the price-primary
}

.price-message {
  color: $price-message-text-color;

  &.price-message-promotion {
    color: $price-message-promotion-text-color;
  }

}

.rooms-remaining {
  color: $product-remaining-text-color;
}

.points-allocation-component > fieldset legend {
  overflow: visible;
}

.strikethrough {
  text-decoration: line-through;
}

.plus {
  color: $plus-text-color;
}

.or {
  color: $or-text-color;
  font-weight: $or-text-font-weight;
  padding: 0 0 3% 0;
}

th,
.th {
  font-weight: $text-strong-font-weight;
}

.box {
  padding: $padding-large-vertical;
  margin: $padding-large-vertical 0;
  border: $box-border;
  border-radius: $box-border-radius;

  &.box-stopover {
    margin: $padding-small-vertical 0;
    background-color: $stopover-bg-color;
    color: $stopover-text-color;
    border: none;
  }
}

// Helpers
// --------------------------------------------------

dl {
  &.inline {
    dt,dd {
      display: inline-block;
    }
  }

  + dl {
    border-top: 1px solid $base-border-color;
    padding-top: $padding-large-vertical * 2;
  }
}

// aligns inline items with spacing
.items-inline {
  div {
    padding-right: $padding-large-horizontal;
    display: inline-block;
  }
}

// Space out room details
.items-span-inline {
  span > * {
    padding-right: $padding-base-horizontal;
  }
}

.text-color {
  color: $text-color;
}

.tag-wrap {
  white-space: inherit;
}

@media (max-width: 320px) { //for a11y
  .col-xxs-12 {
    width: 100%;
  }
}

//full width container to extend past parent width
.width-full {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  &.body-bg {
    background-color: $width-full-page-bg-color;
    padding: $page-area-padded-sm 0;
  }
}

// Vertical divider/pipes between items
// TODO: SS-15776 consider refactor all conditional displays of pipe in hbs templates using css
.divider-pipe {
  position: relative;

  > * {
    margin-right: $divider-pipe-offset;

    &:last-child::after {
      content: none;
    }

    &::after {
      color: $text-color !important;
      content: ' \a0 |';
      position: absolute;
      display: inline-block;
    }
  }
}

/// @group border-helpers
.border-top {
  border-top: 1px solid $base-border-color;
}

.border-bottom {
  border-bottom: 1px solid $base-border-color;
}

.border-left {
  border-left: 1px solid $base-border-color;
}

.border-right {
  border-right: 1px solid $base-border-color;
}

h3.border-bottom,
.h3.border-bottom {
  padding-bottom: $padding-base-vertical;
}

.no-border {
  border: none !important;
}

.no-border-bottom {
  border-bottom: 0 !important;
}

.no-border-radius {
  border-radius: 0 !important;
}

.box-shadow-med {
  @include box-shadow($shadow-md);
}

.no-shadow,
.no-shadow.well,
.no-shadow-hover:hover {
  @include box-shadow(none);
}

/// @group padding-helpers
.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-vertical-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.padding-large {
  padding: $padding-large-vertical $padding-large-horizontal;
}

.padding-large-top {
  padding-top: $padding-large-vertical;
}

.padding-large-bottom {
  padding-bottom: $padding-large-vertical;
}

.padding-small {
  padding: $padding-small-vertical $padding-small-horizontal;
}

.padding-small-bottom {
  padding-bottom: $padding-small-vertical;
}

.padding-left-large-horizontal {
  padding-left: $padding-large-horizontal;
}

.padding-right-lg-h {
  padding-right: $padding-large-horizontal;
}

.padding-left-small-horizontal {
  padding-left: $padding-small-horizontal;
}

.padding-right-small-horizontal {
  padding-right: $padding-small-horizontal;
}

/// @group margin-helpers
.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.margin-large {
  margin: $padding-large-vertical $padding-large-horizontal;
}

.margin-large-bottom {
  margin-bottom: $padding-large-vertical;
}

.margin-large-top {
  margin-top: $padding-large-vertical;
}

.margin-xlarge-bottom {
  margin-bottom: $padding-xlarge-vertical;
}

.margin-small {
  margin: $padding-small-vertical $padding-small-horizontal;
}

.margin-small-top {
  margin-top: $padding-small-vertical;
}

.margin-left-sm {
  margin-left: $padding-small-vertical;
}

.btn-min-width {
  min-width: $btn-min-width;
}

/// @group padding-helpers
.page-area-padded {
  padding: $page-area-padded 0;
}

.page-area-padded-sm {
  padding: $page-area-padded-sm 0;
}

table > caption {
  padding: 0 $table-condensed-cell-padding;
}

.table-thead-alternate th {
  background: $table-alternate-thead-bg-color;
}

.table-tbody-stripe tbody:nth-of-type(even) {
  background: $table-tbody-stripe-bg-color;

  td {
    border-color: $table-tbody-stripe-border-color;
  }
}

.table-tbody-subheader {
  background: $gray-light;
  color: #fff;
}

.table-thead-wrap-text thead th {
  word-break: break-all;
}

.table-auto-width {
  width: auto;
  margin: 0 auto;
}

.table-fixed {
  table-layout: fixed;
  width: 100%;
}

.financial {

  header span {
    color: $section-header-text-color;
    // Overrides above as in this case we don't want too many colors in the header

    &.or {
      color: $or-text-color;
    }
  }

  // Necessary for lte IE8 dual table layout
  .table-container .col-sm-5 {
    margin-left: 0;
  }

  .table {
    border-collapse: separate;
    table-layout: fixed;

    &.table-condensed {
      margin-left: -$table-condensed-cell-padding;
    }

    &.table-bottom-separator {
      border-bottom: 1px solid $price-table-total-border-color;
    }

    > caption {
      text-align: left;
    }

    .or {
      text-transform: uppercase;
    }

    .text-lowercase {
      text-transform: lowercase;
    }

    td,
    th {
      border-top: 0;
      padding-top: 0;
    }

    //thead > tr > th {
    //  border-bottom: 0;
    //}

    hr {
      border: 0;
      border-bottom: 1px solid $price-table-total-border-color;
      margin: 0;
    }

    .total td,
    .total th {
      border-top: 1px solid $price-table-total-border-color;
      border-bottom: 1px solid $price-table-total-border-color;
      padding-top: 5px;
      font-weight: $text-strong-font-weight;
    }

    .col {
      text-align: right;

      .points {
        width: 40%;
      }

      .plus {
        width: 10%;
        padding: 0 2% 0 2%;
      }
    }

    .col-cash > span {
      width: 79%;
    }

  }

  // Adds break before the points value in the header
  h2 .or:after {
    content: "";
    display: block;
  }

  // Cash Table
  .table-container > .row .col-sm-5 .col {
    width: 40%;
  }

  // Redemption Table
  .table-container > .row {
    .col-sm-6, .col-sm-8 {
      @extend .col-lg-offset-1;
      position: relative;

      .col {
        width: auto;
      }

      table .shortfall {
        width: 50%;
        display: inline-block;
      }

      .or {
        position: absolute;
        left: -45px; // For browsers that don't support media-queries
        // Real media-query positioning occurs in app-responsive.scss
        display: none; // Removed temporarily
      }

      .plus {
        padding: 0 2%;
      }
    }
  }

  .line-break th:before {
    display: block;
    content: '';
    height: 10px;
  }
}

.page-body-width {
  margin-left: ($grid-gutter-width / 2);
  margin-right: ($grid-gutter-width / 2);
}

// Price Breakdown Modal
// -------------------------

#pricing-summary-breakdown-modal {
  @extend %wide-modal;
}

#pricing-summary-breakdown-modal .nav-tabs {
  border-bottom: 0;
}

.component-body {
  margin: 0 3%;
}

// Custom form field layout inline-block

.form-inline-block label {
  display: block;
}

.inline-block {
  display: inline-block !important;
  > .input-link {
    display: block;
    margin-top: -12px;
    margin-bottom: 5px;
  }
}

.inline-airport-code {
  vertical-align: inherit;
  min-width: 30px;
  display: inline-block;
}

.inline-airsegment-seatmap-flight-separator {
  padding: 0 3px;
  font-weight: $text-strong-font-weight;
}

.block {
  display: block !important;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

// Add this class on a .row element to remove grid gutters for (all!) children col elements.
.row.compound-input-group {
  margin-right: 0;
  margin-left: 0;

  [class^="col-"],
  [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;

    input,
    select {
      padding: $padding-base-vertical $padding-base-horizontal;
      -webkit-border-radius: 0;
    }
  }

  .input-group {
    .input-group-addon {
      border-radius: 0;
    }

    &:first-of-type .input-group-addon {
      border-radius: $border-radius-base 0 0 $border-radius-base;
    }
  }
}

#pricing-summary {
  // By definition, a span8 would only occur here for when only one pricing summary table exists; as such, we want to ensure it is left aligned within the container
  .col-sm-8 {
    margin-left: 0 !important;
  }
}

.row [class*=span] {
  vertical-align: top;
}

.split-to-columns {
  // Add to a container to split that content into 2 columns - see app-mixins for alternate usages
  @include splitToColumns();
}

.cols-height-match {
  display: table;
  width: 100%;
  table-layout: fixed;

  > [class*='col-'] {
    display: table-cell;
    float: none;
    vertical-align: top;
  }
}

.cols-height-match,
.flex-container {
  > [class*='col-'] {
    padding: $padding-large-vertical ($grid-gutter-width / 2);
  }
}

@media screen\0 {
  .cols-height-match {
    > [class*='col-'] {
      display: block;
      float: left;

      .media-body {
        border-right: 1px solid $product-border-color;
      }
    }

    .price-container {
      height: auto !important;
    }
  }
}

.col-padding {
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
}

.col-1-min {
  min-width: percentage((1 / $grid-columns));
}

.align-with-table-container {
  // Left aligns with table > th,td left padding
  padding-left: $table-cell-padding;
}

.align-with-table-container-condensed {
  // Left aligns with CONDENSED table > th,td left padding
  padding-left: $table-condensed-cell-padding;
}

.valign-bottom {
  vertical-align: bottom !important;
}

.valign-middle {
  vertical-align: middle !important;
}

// Site Header
#site-header .inline-block > label {
  display: block;
  // Outlier for above code
}

#site-header {
  background: $site-header-background;
  color: $site-header-text-color;
  border: 0;
  border-bottom: $site-header-border-bottom-width solid $site-header-border-bottom-color;
  font-size: $site-header-font-size;

  .points {
    color: $site-header-points-text-color;
  }

  .logo-primary {
    @if ($site-header-logo-primary-file-path != "") {
      // Must be full theme path with theme's $assetPath context
      background: transparent url('#{$site-header-logo-primary-file-path}') 0 50% no-repeat;
    }
    background-size: contain;
    min-width: $site-header-logo-primary-min-width;
    min-height: $site-header-logo-primary-min-height;
  }

  .logo-secondary {
    margin-left: 10px;
  }

  .logged-in {
    &.loyalty-links {
      display: flex;
      flex-direction: column;

      .header-brand-row .loyalty-action {
        display: none; //hide button next to logo on desktop
      }
    }

    .header-brand-row {
      flex: auto;
    }

    .navbar-default {
      @media (max-width: $screen-sm) {
        flex: 2;
      }
    }

    .header-brand-container {
      flex: 0 1 50%;
    }
  }

  .logged-out {
    .header-brand-row {
      display: flex;
    }

    .header-brand-container {
      flex: 1;
    }

    .navbar-default {
      flex: auto;
      align-self: flex-end;
    }
  }

  .navbar-toggle {
    .icon::before {
        content: $icon-clear;
      }
    &.collapsed {
      .icon::before {
        content: $icon-menu;
      }
    }
  }

  .header-loyalty-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $padding-large-horizontal;
    z-index: 1001; //on top of .navbar

    .dropdown-menu {
      min-width: $site-header-loyalty-nav-dropdown-min-width;
    }

    .menu-item {
      padding: $site-header-loyalty-menu-item-padding;
      margin-bottom: $padding-large-vertical;
      border-bottom: 1px solid $base-border-color;
      white-space: nowrap;
    }

    .dropdown-menu a {
      color: $link-color;

      &:hover,
      &:active,
      &:focus {
        border-color: $base-border-color;
        background-color: transparent;
        color: $link-color;
        text-decoration: underline;
      }
    }

    > a {
      color: $site-header-loyalty-nav-color;
      display: flex;
      align-items: center;
      border-radius: $border-radius-base;
      padding: $btn-padding-vertical $btn-padding-horizontal;
      border: 1px solid transparent;
      gap: $flex-gap / 2;

      &:hover,
      &:active,
      &:focus {
        border-color: $base-border-color;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .header-loyalty-nav {
      display: block;

      .navbar-nav {
      > li+li {
          border-top: 1px solid darken($navbar-default-bg, 7%);
        }
      }
    }
  }
}

@media (max-width: $screen-sm) {
  #site-header {
    .logged-in,
    .logged-out {
      display: block;

      &.loyalty-links {
        .loyalty-action {
          padding: $header-brand-container-padding $header-brand-container-padding 0 0;
        }
      }

      &.loyalty-links,
      &.loyalty-links .header-brand-row .loyalty-action {
        display: block; //show logout button next to logo on handheld
      }

      .navbar-default {
        flex: 2;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

// Site Footer

#site-footer {
  background: $site-footer-background-color;
  border-top: $site-footer-border-top-width solid $site-footer-border-top-color;
  padding-top: $padding-large-vertical * 2;
  padding-bottom: $padding-large-vertical;
  color: $site-footer-text-color;
  font-size: $site-footer-font-size;
  line-height: $site-footer-line-height;

  a {
    color: $site-footer-link-color;
    text-decoration: $site-footer-link-text-decoration;

    &:hover,
    &:active,
    &:focus {
      color: $site-footer-link-hover-color;
      text-decoration: $site-footer-link-text-hover-decoration;
    }
  }

  .text-inline:not(:last-child) {
    padding-right: $padding-large-horizontal;
  }
}

.page-content > .page-body {
  position: relative;
  overflow: hidden;
}

.page-content .nav-tabs {
  margin-bottom: $line-height-computed;
}

// Page Main Contents Container (main page contents)
// -------------------------

.main-contents-container {
  background-color: $main-contents-container-background-color;
  border-style: solid;
  border-color: $main-contents-container-border-color;
  border-width: $main-contents-container-border-width;

  section {
    background-color: $main-contents-section-background-color;
    margin-bottom: $main-contents-section-bottom-margin;
  }

  section header {
    background-color: $main-contents-section-header-background-color;
    border-bottom: $section-header-border-bottom-width solid $section-header-border-bottom-color;
    padding: 10px 0;
    color: $section-header-text-color;
  }

  section header p {
    padding-top: 0;
    margin-bottom: 0;
  }

  .section-body {
    background-color: $main-contents-section-body-background-color;
    padding: $section-common-padding;
  }

  section footer {
    background-color: $main-contents-section-footer-background-color;
    padding: $section-common-padding;
  }

  .header-note {
    font-size: $font-size-base;
    font-weight: normal;
    text-transform: none;
    float: right;
  }

  .main-contents-container-aside {
    background-color: $aside-contents-section-background-color;
    padding: $section-common-padding-lg;

    section header,
    .section-body {
      background-color: transparent;
    }

    .btn-text-input {
      min-width: 100%;
    }

    @media (max-width: $screen-sm) {
      & {
        background-color: transparent;
        padding: 0;
      }

      section {
        background-color: $aside-contents-section-background-color;
        padding: $padding-large-vertical $padding-large-horizontal;
      }
    }
  }
}

.main-contents-container .section-alt {
  background-color: $main-contents-section-alt-background-color;
  border: $main-contents-section-alt-border-width solid $main-contents-section-alt-border-color;
  padding: $main-contents-section-alt-section-body-padding;
  margin-bottom: $main-contents-section-alt-bottom-margin;

  header {
    background-color: $main-contents-section-alt-header-background-color;
  }

  .section-body {
    background-color: $main-contents-section-alt-body-background-color;
  }

  footer {
    background-color: $main-contents-section-alt-footer-background-color;
  }

}

.textarea { // Emulates <textarea> styling for a given div; for overflow-y to work, need to set height.
  -webkit-appearance: textarea; // Required for Edge and MS Mobile (really)
  appearance: auto;
  border: 1px solid $gray-lighter;
  resize: both;
  overflow-y: auto;
}

// Homepage
// --------------------------
#search-base > .page-body {
  background: $marketing-promotion-groups-bg-color;
}

// Page Aside Contents Container (typically the sidebar; e.g. Itinerary on checkout)
// -------------------------

.aside-contents-container {
  background: $aside-contents-container-background-color;
  border: $aside-contents-container-border-width solid $aside-contents-container-border-color;

  section {
    background: $aside-contents-section-background-color;
    border: $aside-contents-section-border-width solid $aside-contents-section-border-color;
    color: $aside-contents-section-text-color;
    margin-bottom: $aside-contents-section-bottom-margin;
    border-radius: $aside-contents-border-radius;
    @include box-shadow($aside-contents-section-box-shadow);

    .manage-booking & {
      @include box-shadow($shadow-md);
      border: $aside-contents-section-border-width solid $aside-contents-section-border-color;
    }
  }

  section > header {
    background-color: $aside-contents-section-top-header-background-color;
    padding: $aside-contents-section-padding;

    h2 {
      color: $aside-contents-section-top-header-text-color;
    }
  }

  section > footer {
    padding: $aside-contents-section-padding;
  }

  .section-body {
    padding: $aside-contents-section-padding;
  }

  section header {
    border-bottom: $section-header-border-bottom-width solid $section-header-border-bottom-color;
  }

  header.split-header {
    display: flex;
    align-content: space-between;
    > * {
      align-self: center;
    }

    > span {
      text-align: right;
    }
  }

}

.analytics {
  position: absolute;
  top: -9999em;
  border: 0;
  width: 1px;
  height: 1px;
}

// TripAdvisor Ratings
// -------------------------

.trip-advisor {

  li {
    vertical-align: bottom;
    padding-left: 0;
  }

  img {
    position: relative;
    top: -2px;
  }

  .btn-link {
    text-decoration: none;
  }
}

// Star Ratings
// -------------------------

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  font-size: 1.5em;
}

.rating span.star {
  font-family: $app-icon-font-family;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  margin-right: -.3em;

  .popover-content & {
    margin-right: 0; //fixes weird margin issue in bing maps
  }

  &:first-of-type {
    margin-right: 0;
  }
}

.rating.stars1 > span:nth-of-type(n+5):before,
.rating.stars1_5 > span:nth-of-type(n+5):before {
  content: $icon-rating__full;
  color: $star-rating-active-text-color !important;
}

.rating.stars1_5 > span:nth-of-type(4):before {
  content: $icon-rating__half;
  color: $star-rating-active-text-color !important;
}

.rating.stars2 > span:nth-of-type(n+4):before,
.rating.stars2_5 > span:nth-of-type(n+4):before {
  content: $icon-rating__full;
  color: $star-rating-active-text-color !important;
}

.rating.stars2_5 > span:nth-of-type(3):before {
  content: $icon-rating__half;
  color: $star-rating-active-text-color !important;
}

.rating.stars3 > span:nth-of-type(n+3):before,
.rating.stars3_5 > span:nth-of-type(n+3):before {
  content: $icon-rating__full;
  color: $star-rating-active-text-color !important;
}

.rating.stars3_5 > span:nth-of-type(2):before {
  content: $icon-rating__half;
  color: $star-rating-active-text-color !important;
}

.rating.stars4 > span:nth-of-type(n+2):before,
.rating.stars4_5 > span:nth-of-type(n+2):before {
  content: $icon-rating__full;
  color: $star-rating-active-text-color !important;
}

.rating.stars4_5 > span:nth-of-type(1):before {
  content: $icon-rating__half;
  color: $star-rating-active-text-color !important;
}

.rating.stars5 > span:nth-of-type(n+1):before {
  content: $icon-rating__full;
  color: $star-rating-active-text-color !important;
}

//fixes misalignment in IE when span is empty
.rating.stars1 > span:nth-of-type(-n+4),
.rating.stars2 > span:nth-of-type(-n+3),
.rating.stars3 > span:nth-of-type(-n+2),
.rating.stars4 > span:nth-of-type(-n+1) {
  display: none;
}

//fixes misalignment in IE on rating 5
.rating.stars5 {
  direction: ltr;

  span.star:first-of-type {
    margin-right: -.3em;
  }
}

// IE8 Star Ratings
.lt-ie9 .star-active:before {
  content: $icon-rating__full;
  color: $star-rating-active-text-color !important;
}

.lt-ie9 .star-active-half:before {
  content: $icon-rating__half !important;
  color: $star-rating-active-text-color !important;
}

.product-extras {
  @include component-h3;
}

a.product-name {
  font-weight: normal;
}

.middot-span:after,
.middot-span > span:after {
  content: "\00B7";
  padding: 0 5px;
}

.middot-span:last-of-type:after,
.middot-span > span:last-of-type:after {
  content: "";
  padding: 0;
}

.address.list-inline > li:first-of-type {
  padding-left: 0;
}

.map-container {
  .MicrosoftMap {
    border: 1px solid $gray-light;
  }

  .verySmall {
    cursor: pointer !important;
    border: 1px solid $link-color;

    &:hover {
      border-color: $link-hover-color;
      @include box-shadow(0 0 5px $link-color);
    }
  }
}

//hiding icon on map view and in mapview modal
.map-container,
#info-card-modal {
  .special-program-locked::before {
    content: none;
  }
}

.datepicker-dropdown {
  z-index: 9999 !important;
}

.datepicker {
  text-transform: uppercase;
}

.inline-commas {
  > span:after {
    content: ", ";
  }

  > span:last-child:after {
    content: "";
  }
}

.list-inline-commas {

  > li:after {
    content: ",";

    &:last-child:after {
      content: "";
    }
  }
}

.icon-include {
  @include list-icon-bullets($icon-product__included, $icon-include-color);
}

.icon-exclude {
  @include list-icon-bullets($icon-product__excluded, $icon-exclude-color);
}

.list-icon-bullets {
  @include list-icon-bullets();

  &.list-amenities li:before {
    color: $list-icon-amenities-color;
  }

  > li {
    &.warning-icon-color:before {
      color: $brand-warning;
    }

    &.success-icon-color:before {
      color: $brand-success;
    }

    //Hotel amenities icons

    &.icon-adults-only:before {
      content: $icon-adults-only;
    }

    &.icon-airport-shuttle:before {
      content: $icon-airport-shuttle;
    }

    &.icon-all-inclusive:before {
      content: $icon-all-inclusive;
    }

    &.icon-babysitting-child-care:before {
      content: $icon-babysitting-child-care;
    }

    &.icon-bar-lounge-onsite:before {
      content: $icon-bar--lounge;
    }

    &.icon-beach:before {
      content: $icon-beach;
    }

    &.icon-business-center:before {
      content: $icon-business-center;
    }

    &.icon-casino:before {
      content: $icon-casino;
    }

    &.icon-children-teen-programs:before {
      content: $icon-teen-programs;
    }

    &.icon-continental-meal-plan:before {
      content: $icon-continental-breakfast;
    }

    &.icon-free-parking:before {
      content: $icon-free-parking;
    }

    &.icon-golf:before {
      content: $icon-golf;
    }

    &.icon-gym:before {
      content: $icon-gym-fitness;
    }

    &.icon-handicap-facilities:before {
      content: $icon-wheelchair-accessible;
    }

    &.icon-internet:before {
      content: $icon-internet-device-hub;
    }

    &.icon-meeting-rooms:before {
      content: $icon-meeting;
    }

    &.icon-pets-allowed:before {
      content: $icon-pets;
    }

    &.icon-restaurant-onsite:before {
      content: $icon-restaurant;
    }

    &.icon-room-service:before {
      content: $icon-room-service;
    }

    &.icon-ski-in-ski-out:before {
      content: $icon-ski-in-out;
    }

    &.icon-small-pets-allowed:before {
      content: $icon-pets--small;
    }

    &.icon-smoke-free:before {
      content: $icon-smoke-free;
    }

    &.icon-spa:before {
      content: $icon-spa;
    }

    &.icon-swimming-pool:before {
      content: $icon-pool--swimming;
    }

    &.icon-tennis:before {
      content: $icon-tennis;
    }

    &.icon-wireless-internet:before {
      content: $icon-wifi;
    }

    // Branded fare icons
    &.icon-branded-fares__available-for-charge:before {
      color: $branded-fare-available-for-charge-icon-color;
      content: $icon-branded-fares__available-for-charge;
    }

    &.icon-branded-fares__included:before {
      color: $branded-fare-included-icon-color;
      content: $icon-branded-fares__included;
    }

    &.icon-branded-fares__not-available:before {
      color: $branded-fare-not-available-icon-color;
      content: $icon-branded-fares__not_available;
    }
  }

  .list-icon-bullets-specials > li:before {
    content: '\e938'; // icon-list-item__special
  }

  .list-icon-bullets-prepaids > li:before {
    content: '\e93a'; // icon-list-item__prepaid
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: $screen-xs) {
  .text-ellipsis-xs {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.list-no-indent {
  padding-left: 0;
  list-style-position: inside;
}

.list-align {
  padding-left: $padding-large-horizontal;
  list-style-position: outside;
}

.list-ellipsis > li {
  @extend .text-ellipsis;
}

.list-icon-bullets-container ul {
  @extend .list-icon-bullets;
}

.list-styled {
  list-style: disc;

  li {
    list-style-type: disc;
  }
}

.list-panels {
  @extend .p;
  @extend .list-unstyled;
  border: 1px solid $base-border-color;

  li {
    padding: $padding-large-vertical $padding-large-horizontal;
  }

  li + li {
    border-top: 1px solid $base-border-color;
  }
}

.list-divider {
  li {
    padding: $padding-large-vertical 0;
  }

  li:not(:last-of-type) {
    border-bottom: 1px solid $base-border-color;
  }
}

.step-indicator {
  margin: 5px 0;
  line-height: 30px;
  @include flexbox();

  li {
    background: $step-indicator-step-background-color;
    color: $step-indicator-step-text-color;
    padding-left: 15px;
    padding-right: 12px;
    height: 30px;
    margin-right: 20px;
    position: relative;
    text-align: center;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      left: -15px;
      border: 15px solid transparent;
      border-color: $step-indicator-step-background-color;
      border-left-color: transparent;
      border-right: 0;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 0;
      right: -30px;
      border: 15px solid transparent;
      border-left-color: $step-indicator-step-background-color;
    }

    &:first-of-type {
      padding-left: 15px;

      &:before {
        display: none;
      }
    }
  }
}

// Ellipsis Loader stuff

.ellipsis-loader > span {
  width: .3em;
  height: .3em;
  background-color: currentColor;
  display: inline-block;
  border-radius: 50%;
  @include animation(ellipsis-loader-animation 1.5s infinite ease-in-out);
  // Prevent first frame from flickering when animation starts
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin-right: 2px;

  &:nth-child(1) {
    @include animation-delay(-0.5s);
  }

  &:nth-child(2) {
    @include animation-delay(-0.2s);
  }
}

@keyframes ellipsis-loader-animation {
  0%, 80%, 100% {
    @include transform(scale(1));
    @include opacity(1);
  }

  40% {
    @include transform(scale(0));
    @include opacity(0);
  }
}

@include customSelectIcon();

// Modify Search Failure
// SG TODO: All of this looks incorrect

.modify-search-container {
  margin: ($padding-large-vertical * 15) auto;
  max-width: $screen-sm;

  h3 {
    color: $text-color;
    line-height: calc(#{$font-size-h3} * #{$line-height-base});
    margin-bottom: $padding-large-vertical * 6;
  }
}

.skip-links {
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 9;

  a {
    position: absolute;
    left: -10000px;
    top: 5px;
    min-width: 200px;
    text-align: center;
    color: $link-color !important;

    &:focus,
    &:hover,
    &:active {
      left: 5px;
      background: #fff !important;
      color: $link-hover-color !important;
    }
  }
}

#search-base {
  .page-header {
    background: linear-gradient($hero-gradient-direction, $hero-gradient-first-color 0%, $hero-gradient-second-color 50%, $hero-gradient-third-color 100%);
  }
}

// Search Home Marketing Hero Messaging
// --------------------------------------------------
.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: $homepage-background-attachment;
  background-position: $homepage-background-position;
  min-height: $search-form-hero-height;
}

.hero-message {
  padding: $padding-large-vertical*2 0;
  color: $hero-message-text-color;
  margin-bottom: $page-area-padded-sm; // when banner is at top
  border-radius: $hero-message-border-radius;
  @include alpha-background-color(rgba($hero-message-bg-color, $hero-message-background-opacity), $text-color);

  &.background-transparent {
    background-color: transparent;
    margin-bottom: 0;

    & {
      text-shadow: $hero-message-text-shadow;
    }

    &.bottom {
      padding-bottom: $page-area-padded-sm;
      padding-top: 0;
    }
  }

  .hero-title {
    font-size: $hero-message-header-font-size;
    margin-bottom: $line-height-computed/2;
    font-weight: $headings-font-weight-h2;
  }

  .hero-description {
    font-size: $hero-message-description-font-size;
  }

  + .page-area-padded-sm {
    padding-top: 0;
  }

  &.bottom {
    margin-bottom: 0;
  }
}

.kard.search_form_hero {
  background: transparent;
}

//Pushes footer out of view when app is loading
.modal-open #shopping-page-container {
  min-height: 100vh;
}

/// @group panels
.panel {
  @include box-shadow($shadow-sm);

  a {
    color: currentColor !important;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  &.panel-bg-default {
    background: $panel-default-heading-bg;
  }

  &.panel-bg-primary {
    background: $panel-primary-heading-bg;
  }

  &.panel-bg-success {
    background: $panel-success-heading-bg;
  }

  &.panel-bg-warning {
    background: $panel-warning-heading-bg;
  }

  &.panel-bg-danger {
    background: $panel-danger-heading-bg;
  }

  &.panel-bg-info {
    background: $panel-info-heading-bg;
  }

  &.branded-fare-selected-panel {
    background: #fff;
    border: 3px solid $brand-success;
  }

  &.panel-loyalty-promo {
    background: $loyalty-deal-bg-color;
    color: $loyalty-deal-text-color;

    .icon {
      font-size: 1.5em;
      margin-right: 5px;
    }
  }
}

.panel-notice {
  .icon {
    color: $panel-default-icon-color;
  }
}

.icon.top {
  vertical-align: top;
}

.panel-price {
  background-color: $state-info-bg;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: $text-color;
  border: none;

  .panel-body-top {
    color: $panel-price-body-top-color;
    font-family: $panel-price-body-top-font-family;
  }

  .panel-body-bottom {
    font-family: $panel-price-body-bottom-font-family;
    font-size: $font-size-large;
  }
}

// Daily room rates tables
.daily-room-rates-table {

  &.two-columns-room-rates-table {
    background-color: transparent;

    & > tr > td:first-child {
      padding-left: 20px;
    }

    & > tr > td:last-child {
      text-align: right;
    }
  }
}

// Checkbox toggle
// -------------------------

.checkbox-toggle {
  margin-bottom: $padding-large-vertical;

  > label {
    padding-left: 0;
    width: initial;
    display: inline-block; //contains the click area within the label
  }

  .checkbox-toggle-state-on {
    display: none;
    opacity: 0;
  }

  .checkbox-toggle-switch-container {
    display: inline-block;
  }

  .checkbox-toggle-switch {
    display: block;
    width: 3.25em;
    height: 1.5em;
    border: 1px solid $checkbox-toggle-border-color-disabled;
    padding: 10px;
    border-radius: 20px;
    transition: all 0.25s cubic-bezier(0.75, 0, 0.08, 1);
    position: relative;
    background: $checkbox-toggle-bg-color-disabled;
    @include box-shadow($shadow-inset);

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 18px;
      height: 18px;
      background-color: $checkbox-toggle-handle-bg-color-disabled;
      border-radius: 50%;
    }
  }

  [type="checkbox"] {
    width: 1px;
    height: 1px;
    border: 0;
    clip: rect(0 0 0 0);
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;

    &:focus {
      ~ span .checkbox-toggle-switch {
        box-shadow: 0 0 5px 2px $link-color;
      }
    }

    &:active, &:hover {
      ~ span .checkbox-toggle-switch {
        background-color: $checkbox-toggle-bg-color-hover;
        border-color: $checkbox-toggle-border-color-hover;
      }
    }

    &:checked ~ span .checkbox-toggle-switch {
      border-color: $checkbox-toggle-border-color-active;
      background-color: $checkbox-toggle-bg-color-active;

      &:before {
        content: $icon-input__checkbox-toggle--selected;
        font-family: switchflyShopping;
        color: #fff;
        position: absolute;
        top: 1px;
        left: 4px;
      }

      &:after {
        background-color: $checkbox-toggle-handle-bg-color-active;
        left: auto;
        right: 1px;
      }
    }

    &:checked ~ span .checkbox-toggle-state {
      &.checkbox-toggle-state-on {
        display: block;
        opacity: 1;
      }

      &.checkbox-toggle-state-off {
        display: none;
        opacity: 0;
      }
    }
  }
}

pre {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
}

.navbar-toggle {
  border-radius: $btn-border-radius; // Overrides bootstrap navbar toggle setting
}

@media (min-width: $screen-sm) {
  .navbar-toggle,
  .navbar-header {
    display: none;
  }
}

// App Loader and Interstitial
// -------------------------
.app-loading,
.page-interstitial {
  header,
  footer {
    display: none;
  }
}

#app-loading-indicator,
#interstitial-container {
  background-color: $body-bg;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .swf-stubs & {
    top: auto;
  }
}

.checkbox-toggle-grid {
  display: grid;
  grid-template-columns: minmax(max-content, auto) minmax(max-content, auto) 1fr;

  > div:first-of-type {
    margin-right: $padding-large-horizontal;
  }
}

.icon-flight__direction:before {
  content: $icon-flight__direction;
}


/// Used for filter and points slider
.slider {
  .slider-handle {
    background: $filter-slider-handle-background-color;
    border: $filter-slider-handle-border-width solid $filter-slider-handle-border-color;
    @include box-shadow($shadow-md);
    @include opacity(1);
  }

  .slider-track {
    background: $filter-slider-track-background-color;
    border: 1px solid $filter-slider-track-background-color;
  }

  .slider-selection {
    background: $filter-slider-selection-background-color;
  }
}

.image-btn {
  position: relative;
  padding: 0;
  border: none;
  border-radius: $image-btn-border-radius;
  overflow: hidden;
  color: white;
  width: 100%;

  img {
    width: 100%;
  }

  .image-btn-label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    background: $btn-image-label-opacity;
    border-radius: $border-radius-base;

    .icon {
      margin-right: $padding-small-vertical;
    }
  }
}

.case-handheld .products-rooms .image-btn {
  border-radius: 0; // HH cards don't yet support this
}

.product-notice { //product results & details
  line-height: $product-notice-line-height;
}

// Grouped Products

#grouped-products {
  .aside-contents-container {
    position: absolute;
    top: 0;
    z-index: 1000;
    visibility: hidden;
    width: 50%;
    @include transition(all 0.5s);
    @include transform(translate3d(200%, 0, 0));
    padding: 0;

    section:last-of-type {
      margin-bottom: 0;
    }

    &.toggle-container-show {
      visibility: visible;
      @include transform(translate3d(100%, 0, 0));
      @include box-shadow(-5px 1px 5px rgba(0, 0, 0, .2));
    }

    .visible-tablet-inline-block {
      display: inline-block;
    }

    section {
      margin-bottom: 0;
    }
  }

  #search-results-form-container {
    width: 100%;
  }
}

// Dynamic toolbars
//------------------------------
// e.g. horizontal sort menu used on results pages

.toolbar {
  border-radius: $toolbar-border-radius !important;

  &.toolbar-lg {
    padding: 15px;
  }

  &.no-shadow {
    @include box-shadow(none);
  }

  &.toolbar-dynamic {
    @include box-shadow(none);
    padding: 0;
    background-color: $toolbar-dynamic-bg-color;

    .toolbar-container {
      @extend .flex-container;
      align-items: center;
    }

    .toolbar-label {
      @extend .text-strong;
      white-space: nowrap;
      align-content: center;
      padding: 0 $padding-large-horizontal;
    }

    .toolbar-items {
      @extend .no-border;
      @extend .no-margin;
      overflow: hidden;

      li.is-hidden {
        position: absolute;
        visibility: hidden;
        z-index: -1;
      }

      > li.active > a {
        background: transparent;
      }
    }

    .dropdown {
      @include flexbox;
      margin-left: auto;
      align-self: stretch;

      > a {
        @extend .flex-valign-middle;
        white-space: nowrap;
        padding: 0 $padding-large-horizontal;
      }
    }

    .dropdown-hide {
      visibility: hidden;
      z-index: -1;
    }

    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}

// DEALS and PROMOTIONS
.promotion-notation {
  padding: $promotion-notation-padding;
  background: $promotion-notation-bg-color;
  color: $promotion-notation-text-color;
  margin-right: $padding-base-horizontal;
  border-radius: $promotion-notation-border-radius;
  font-weight: $promotion-notation-font-weight;

  &.loyalty-deal {
    background: $loyalty-deal-bg-color;
    color: $loyalty-deal-text-color;
    display: inline-block;

    span {
      color: $loyalty-deal-text-color;
    }

    span:not(:first-of-type) {
      display: inline-block;
      margin-left: $padding-small-horizontal;
    }
  }
}

.specials-notation {
  text-transform: $product-promo-text-transform;
  font-weight: $specials-notation-font-weight;
}

//SUB HEADER
.sub-header {
  background-color: $sub-header-bg-color;
  width: 100%;
}

// Fix for Google Translate issue TP#253809
[class^="translated-"] {
  .air-legs font,
  .product-summary-container font {
    display: inline-flex;
    flex-wrap: wrap;
  }
}

//background stripe does not highlight radio button
.radio-stripe {
  .radio {
    padding-left: 0;
  }

  label > span {
    display: inline-block;
    min-width: 100%;
    padding: 2px;
  }

  input[type="radio"]:checked + span {
    background: $brand-active;
  }
}

//background stripe extends full width of radio list item
.radio-stripe-full {
  li.checked,
  li.default-checked { //used when radio is not checked but highlighted
    background: $brand-active;
  }

  .li.default-unchecked {
    background: none;
  }

  .radio {
    padding: $padding-small-horizontal*2 $padding-small-horizontal;
    margin: 0;
  }

  .radio {
    label {
      padding: 0;
    }

    input[type="radio"] {
      margin: $padding-small-vertical 0 0 0;

      + span {
        margin-top: 2px;
      }
    }
  }
}

.input-disabled {
  pointer-events: none;
  color: $text-disabled;
  opacity: 0.5;
}

.page-body-vh {
  min-height: auto;
}


// Bootstrap extensions
// NOTE: This section just extends some bootstrap patterns so that we keep consistency
// -------------------------

.text-pending {
  color: $state-pending-text;
}

// hiding amazon chat widget by default
// see application_state action for show/hide logic
#amazon-connect-chat-widget {
  display: none;
}

.page-specials {
  .page-content > .page-body {
    overflow: visible;
  }
  .main-contents-container .main-contents-container-aside {
    padding-bottom: 6px;
  }
}

.text-break-word {
  word-break: break-word;
}

// Activity Details DT+HH
.product-detail {
  .product-aside {
    padding: $padding-common-md;
    @extend .product-body;
  }

  .product-attributes {
    > .flex-container {
      &:nth-child(1),
      &:nth-child(2) {
        max-width: 300px;
      }
    }
  }

  .itinerary-stops {
    margin-left: $padding-common-md;

    .dot {
      position: absolute;
      left: -16px;
      top: 4px;
      width: 12px;
      height: 12px;
      background-color: $gray;
      border-radius: 50%;
      border: 2px solid white;
    }

    .itinerary-stop.no-line .list-unstyled > li:before {
      //hop on hop off with missing stops
      content: none;
    }

    .itinerary-stop {
      display: flex;
      align-items: flex-start;
      position: relative;

      .stop-sm {
        padding-bottom: $padding-common-md / 2;
      }

      .stop-lg {
        padding-bottom: $padding-common-md;
      }

      .list-unstyled > li {
        position: relative;
        @extend %line-path;
      }

      @extend %line-path;

      .day-stop {
        padding-left: $padding-common-md;
      }
    }

    %line-path {
      &:before {
        content: "";
        position: absolute;
        left: -11px;
        top: 5px;
        bottom: -12px;
        width: 2px;
        background-color: $gray;
      }

      &:last-child {
        margin-bottom: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .itinerary-accommodation {
    > span:first-of-type {
      font-weight: $text-strong-font-weight;
    }
  }
}

.select-disabled::after { //hides the down arrow
    display: none;
}
