/* CHECKOUT PAGE */
// Extends main-contents-container

#checkout-form-container,
#traveler-info-container {
  padding: $checkout-form-container-padding;

  section {
    margin: $checkout-section-margin;

    header {
      background-color: $checkout-section-header-background-color;
      padding: $checkout-section-header-padding;
      color: $checkout-section-header-text-color;
    }

    .air-leg {
      border-bottom: 1px solid $hr-border;
      margin-bottom: $line-height-computed;
      padding-bottom: $line-height-computed;

      &:last-of-type {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .section-body {
      background-color: $checkout-section-body-background-color;
      padding: $checkout-section-body-padding;

    }

    &#purchase {
      header {
        background-color: $checkout-financial-header-background-color;
        color: $checkout-financial-header-text-color;
      }

      h2 {
        font-weight: $pricing-summary-font-weight; //option to set this to bold when client h2 font is not
      }

      .section-body {
        background-color: $checkout-financial-body-background-color;
      }

      .purchase-button-container {
        text-align: $checkout-purchase-button-alignment;
      }

      #purchase-button {
        margin: $checkout-financial-purchase-button-margin;
      }

      .iframe-seamless-container {
        max-height: 20vh;
        background-color: $checkout-terms-conditions-background-color;
        color: $checkout-terms-conditions-text-color;
        font-size: $checkout-terms-conditions-font-size;
      }
    }
  }

  & + .aside-contents-container {
    padding: $checkout-aside-container-padding;

    section {
      & > header {
        background-color: $checkout-aside-section-header-background-color;
        border-color: $checkout-aside-section-header-border-color;

        h2 {
          color: $checkout-aside-section-header-text-color;
        }
      }

      .section-body {
        background: $checkout-aside-section-body-background-color;

        header {
          border-color: $checkout-aside-section-header-border-color;
        }

        .components-container {
          h4 {
            font-weight: $checkout-aside-section-component-h4-font-weight;
            display: inline-block;
          }

          .edit-ancillary-link {
            float: right;
            margin: 10px 0;
          }

          .component {
            border-color: $checkout-aside-section-component-border-color;

            .icon-collapsible--closed {
              color: $gray-dark;
            }

            .text-info {
              color: $checkout-aside-section-component-text-info-color;
            }
          }
        }
      }
    }
  }

  .travelers-details {
    legend {
      background-color: $checkout-travelers-details-legend-background-color;
      padding: $checkout-travelers-details-legend-padding;
      text-transform: $checkout-travelers-details-legend-text-transform;
      font-weight: $checkout-travelers-details-legend-font-weight;
    }

    .special-service-requests {
      legend {
        background-color: initial;
        padding: initial;
        font-weight: initial;
      }

      .checkbox {
        margin-bottom: 15px;
      }
    }
  }

  #pricing-summary {
    h2 {
      font-weight: $pricing-summary-font-weight; //option to set this to bold when client h2 font is not
    }

    caption {
      padding: $checkout-pricing-summary-caption-padding;
    }

    .component-section:only-of-type {
      padding-bottom: $padding-large-vertical;
      border-bottom: 1px solid $gray-lighter;
    }
  }

  .travelers-details {
    .component-section {
      border-bottom: none;
    }
    .component-section:last-of-type {
      border-bottom: 1px solid $base-border-color;
    }
  }

  .section-body ul {
    @include no-bullets;

    &.list-styled li {
      margin-left: $padding-large-horizontal !important;
      list-style: disc !important;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    margin-top: $alert-margin-top;

    &.nav-tabs {
      margin-bottom: $line-height-computed;
    }
  }

  #car-product-tab-pane {
    ul {
      padding-left: 40px;
      margin-bottom: 10px;
    }
  }

  .nav-pills {
    padding-bottom: $padding-large-vertical * 2;
  }

  .travelers .section-body .traveler {
    border-bottom: 1px solid $gray-lighter;
    @extend .p;
    padding-bottom: 10px;
  }

  .travelers .section-body li:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .added-traveler {
    text-transform: uppercase;
  }

  .traveler:last-of-type .row > [class*="span"] {
    min-height: 0;
  }

  .sec-code {
    background: transparent url('#{$assetsPath}/cvv2/security_code.png') top left no-repeat;
    height: 30px;
    width: 117px;
    position: relative;
    top: 2px;
    left: 5px;
    overflow: hidden;
    // Use mixin secCodeVariant in client theme if you want to show only amex card or only non-amex card help image.
    // @include secCodeVariant(amex); for amex only, or @include secCodeVariant() for non-amex only
    // For both, just don't include the mixin
  }

  .payment .has-icons {
    label {
      display: grid;
      grid-template-columns: 12px auto auto;
      grid-column-gap: $padding-base-horizontal;
    }
  }

  .payment-option-icon,
  .payment-option-icon-lg {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    justify-self: end;
    width: $checkout-payment-option-icon-width;
    height: $checkout-payment-option-icon-height;
  }

  .payment-option-icon-lg {
    width: $checkout-payment-option-icon-lg-width;
    height: $checkout-payment-option-icon-lg-height;
  }

  .marcuspay-payment-option {
    .payment-option-icon,
    .payment-option-icon-lg {
      background-image: $checkout-payment-option-icon__marcus-pay;
    }
  }

  .creditcard-payment-option, .depositmodule-payment-option {
    .payment-option-icon {
      background-image: $checkout-payment-option-icon__creditcard;
    }
  }

  .paypal-payment-option .payment-option-icon {
    background-image: $checkout-payment-option-icon__paypal;
  }

  .banktransfer-payment-option .payment-option-icon {
    background-image: $checkout-payment-option-icon__banktransfer;
  }

  .points-payment-option {
    margin-bottom: $line-height-computed;
    margin-left: $padding-large-vertical * 2;

    &:last-of-type {
      margin-bottom: 0;
    }

    input[type="radio"] {
      margin-top: 0;
    }
  }

  .points-payment-option-body {
    margin: $line-height-computed 0;
  }

  // Unfortunately need to select ember nodes
  .component-section .span9 > .ember-view:last-of-type .air-passenger {
    border-bottom: 0;
    margin-bottom: 0;
  }

  #payment li {
    margin-bottom: 5px;
  }

  #purchase .section-body {
    @extend .text-center;
  }

  .seat-selection .component-section i {
    vertical-align: middle;
  }

  #discounts-container {

    input {
      margin-bottom: 0;
    }

    .promo-toggle {
      display: none;
    }
  }

  .truncate-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  .payment-supplier-logo {
    li {
      height: 40px;
      margin-left: $grid-gutter-width / 2;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .slider.slider-horizontal {
    width: 99%;
    left: 0;
  }

  .p + .points-slider-label {
    margin-top: $line-height-computed;
    line-height: initial;
  }

  .points-slider-component {
    margin-bottom: $input-height-base;
  }

  .points-slider-label {
    @include component-h3;
  }

  .points-slider-total .well {
    margin-bottom: 0;
  }

  .points-slider-commit {
    padding: 5px 0 10px;

    a {
      font-weight: 500;
    }

    button {
      margin-left: 30px;
    }
  }

  .insurance-amount {
    margin: 15px 0;

    .insurance-amount-update-text {
      font-style: italic;
    }
  }
}

#pricing-summary .table-container .table-condensed {
  .payment-received-table {
    padding-top: 5px;
  }

  .remaining-balance-due-table {
    background-color: #fff4ea;
  }
}

#display-terms,
#display-terms * {
  font-size: $checkout-display-terms-font-size;
  line-height: $checkout-display-terms-line-height;
}

.case-handheld {
  #checkout-form-container {
    #discounts-container {
      margin-top: $padding-large-vertical * 2;
      margin-bottom: $padding-large-vertical;
    }
  }
}

.insurance {
  .section-body {
    background-color: $checkout-insurance-section-body-background-color;
    color: $checkout-insurance-section-body-text-color;

    a {
      color: $checkout-insurance-section-body-link-color;
    }

    .radio {
      label {
        display: grid;
        grid-template-columns: 20px 1fr;
      }
    }

    .error {
      fieldset {
        margin-top: $padding-large-vertical;
        padding: $padding-base-vertical;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  .insurance-image {
    max-width: $checkout-insurance-section-image-max-width-handheld;
  }
}


.selected-traveler-profile-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 9px;
  padding-bottom: 20px;
  pointer-events: none;

  &.icon-circle:before {
    background-color: $brand-primary;
  }

  &.icon-user__add:before{
    color: $brand-primary;
    font-size: 24px;
  }
}

.traveler-dropdown-menu {
  top: 80%;
  left: 8px;
  width: 100%;
  overflow: hidden;

  li > a {
    padding: 6px 8px;
    font-size: $font-size-base;

    .icon-user__add:before {
      color: $gray-light;
      font-size: 24px;
    }

    .icon-user__base:before {
      color: $body-bg;
    }

    &:hover,
    &:focus {
      background-color: $brand-active;
      color: $gray-dark;

      .icon-circle:before {
        background-color: $brand-primary;
      }

      .icon-user__add:before {
        color: $brand-primary;
      }
    }

    &.disabled {
      color: $gray-medium !important;
      background-color: $body-bg !important;

      .icon-circle:before {
        background-color: $gray-lighter;
      }
    }

    &.selected {
      background-color: $brand-active;
    }
  }
}

.traveler-dropdown-default-name {
  padding-left: 8px !important;
}

.traveler-dropdown-traveler-name {
  padding-left: 2.6em !important;
}
